
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import PageAuth from '@/components/layout/PageAuth.vue'

export default defineComponent({
  components: {
    PageAuth,
    TmButton,
    TmFormLine,
  },
  setup() {
    const message = ref('')
    const commentAdded = ref(false)

    return {
      message,
      commentAdded,
    }
  },
})
